import { h, Component } from 'preact';
//import Helmet from "preact-helmet";
//import Helmet from "./helmet";
import { Router } from 'preact-router';
import { withData, setInitialData, setData, clearData, updateData} from './comms';
import Sidebar from './sidebar';
import Home from '../routes/home';
import Popup from './popup';
import audioplayer from './audioplayer';
const webrequest = require('./webrequest');
const config = require('../../deployedConfig.json');
//import Home from './home';


function uuid() { //uuidv4
    if(window.crypto || window.msCrypto) {
        var xCrypto = window.crypto || window.msCrypto;
        return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
            (c ^ xCrypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
          );
    }
    else {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
          });
    }
}

var defaultStore = {
    stats: {
        online: true,
        projects: 2
    },
    activity: "coding",
    availability: {
        weekly: [

        ],
        today: [

        ]
    },
    location: {
        x: "1824",
        y: "720",
        name: "Nelson, New Zealand",
        timezoneoffset: 13,
        internetconnection: {
            status: "Excellent",
            color: "#0f0"
        }
    },
    feed: [
    ]

};
/*
var newStore = {
    stats: {
        online: true,
        projects: 2
    },
    activity: "sleep",
    availability: {
        weekly: [
            {date:'09 Feb',hours:5},
            {date:'16 Feb',hours:40},
            {date:'23 Feb',hours:40},
            {date:'01 Mar',hours:40},
            {date:'08 Mar',hours:30}, //Rina Away
            {date:'15 Mar',hours:5},
            {date:'22 Mar',hours:30}, //Rina Away
            {date:'29 Mar',hours:30}, //Rina Away
            {date:'05 Apr',hours:30}, //Easter
            {date:'12 Apr',hours:20}, //School Holidays
            {date:'19 Apr',hours:20}, //School Holidays
        ],
        today: [
        ]
    },
    location: {
        x: "1024",
        y: "120",
        name: "Nelson, New Zealand",
        timezoneoffset: 13,
        internetconnection: {
            status: "Excellent",
            color: "#0f0"
        }
    },
    feed: [
    ]

};
*/
/*
var newStore2 = {
    stats: {
        online: true,
        projects: 2
    },
    activity: "coding",
    availability: {
        weekly: [
            {date:'09 Feb',hours:5},
            {date:'16 Feb',hours:40},
            {date:'23 Feb',hours:40},
            {date:'01 Mar',hours:40},
            {date:'08 Mar',hours:30}, //Rina Away
            {date:'15 Mar',hours:40},
            {date:'22 Mar',hours:30}, //Rina Away
            {date:'29 Mar',hours:30}, //Rina Away
            {date:'05 Apr',hours:10}, //Easter
            {date:'12 Apr',hours:20}, //School Holidays
            {date:'19 Apr',hours:20}, //School Holidays
        ],
        today: []
    },
    location: {
        x: "1824",
        y: "720",
        name: "Nelson, New Zealand",
        timezoneoffset: 13,
        internetconnection: {
            status: "Excellent",
            color: "#0f0"
        }
    },
    feed: [
    ]

};
*/



class App extends Component {
    constructor(props){
        super(props);

        this.websocketConnected = false;

        this.websocketConnection = null;

        this.websocketHeartbeatInterval = null;

        this.audioBuffers = {};

        this.session = null;

        this.state = {
            popup: false,
            popupmessage: ""
        };


        setInitialData(this.props.defaultStore || defaultStore);
    }


   componentDidMount() {



        if (typeof window !== "undefined") {
            
            this.createSession();
            audioplayer.init();
            audioplayer.load("button", "/assets/audio/button.mp3");
            this.refreshData();
            this.refreshInterval = setInterval(() => {
                this.refreshData();
            }, 60000);


        }

    }

    refreshData = () => {
        webrequest.send(config.resourcesurl + '/status.json', 'GET', {}, 
        (data) => {
            setData(data);
            if(data.connect == true && this.websocketConnected == false) {
                this.connectWebsocket();
            }
            else if(data.connect == false && this.websocketConnected == true) {
                this.disconnectWebsocket();
            }
            else {
                // do nothing - we must be in the right state.
            }
        },
        () => {});
    }

    connectWebsocket = () => {

        this.websocketConnection = new WebSocket(config.websocketendpoint + "?session=" + this.session);

        this.websocketConnection.onopen = (event) => {
            this.websocketConnected = true;
            console.log("Websocket connection opened.");
            if(this.websocketHeartbeatInterval === null) {
                this.websocketHeartbeatInterval = setInterval(() => {
                    this.heartbeatWebsocket();
                }, 90000);
            }
        };

        this.websocketConnection.onclose = (event) => {
            console.log("Websocket connection closed.");
            if(this.websocketConnected == true) //i.e. we haven't intentionally closed the connection
            {
                this.connectWebsocket();
            }
            else {
                this.websocketConnection = null;
                if(this.websocketHeartbeatInterval !== null) {
                    clearInterval(this.websocketHeartbeatInterval);
                    this.websocketHeartbeatInterval = null;
                }
            }
        };

        this.websocketConnection.onmessage = (event) => {

            console.log("Message Received: " + event.data);

            var payload = {};

            try {
                payload = JSON.parse(event.data);
            }
            catch(jsonError) {

            }
            var eventName = payload.event;
            var data = payload.data;

            if(eventName == "refreshStatus") {
                this.refreshData()
            }
            if(eventName == "popup") {
                this.setState({popup: true, popupmessage: data.message});
            }
            else if(eventName == "play") {
                audioplayer.load(data.url, data.url, true);
            }
            
        }

    }

    heartbeatWebsocket = () => {

        if(this.websocketConnection && this.websocketConnection.readyState === WebSocket.OPEN) {
            console.log("Sending heartbeat to websocket");
            this.websocketConnection.send(JSON.stringify({event: "heartbeat"}));
        }
        else {
            console.log("Websocket not ready. Not sending heartbeat.");
        }
    }

    disconnectWebsocket = () => {
        this.websocketConnected = false;
        if(this.websocketConnection) {
            this.websocketConnection.close();
        }
    }

    componentWillUnmount() {
        if (typeof window !== "undefined") {
            clearInterval(this.refreshInterval);
            this.disconnectWebsocket();
        }
    }

    handleRoute = e => {
        this.currentUrl = e.url;
    };

    createSession = () => {
        this.session = localStorage.getItem('session');

        if(!this.session) {
            this.session = uuid();
            localStorage.setItem("session", this.session);
        }
    }

    closePopup = () => {
        audioplayer.play("button");
        this.setState({popup: false});
    }

    
    render() {

        const customHistory = this.props.href ? {
            location: { pathname: this.props.href },
            //listen: () => {}
          } : undefined;


        return (
            <div id="app" style={{minHeight: "100%"}}>
                <div className="row" style={{minHeight: "100%"}}>
                    <Sidebar />
                    <Router onChange={this.handleRoute} history={this.props.path ? {location: { pathname: this.props.path }, listen: () => {}} : undefined}>
                        <Home path="/" />
                    </Router>
                    {this.state.popup == true ? <Popup message={this.state.popupmessage} onClick={this.closePopup} /> : null}
                </div>
            </div>
        );
    }
    
        
}

export default App;