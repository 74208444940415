

var audioPlayerContext = null;
var audioBuffers = {};
var audioTag = null;

var initiated = false;

const init = () => {
    if(typeof window !== 'undefined' && initiated == false) {
        var AudioPlayerContext = window.AudioContext = window.AudioContext || window.webkitAudioContext;
        audioPlayerContext = AudioPlayerContext ? new AudioPlayerContext() : null;
        audioTag = (!audioPlayerContext) ? new Audio() : null;
        initiated = true;
    }
}


const load = (key, url, playOnLoad = false) => {
    // Decode asynchronously
    if(audioBuffers[key]) {
        if(playOnLoad == true) {
            play(key);
        }
    }   
    else {
        var request = new XMLHttpRequest();
        request.open('GET', url, true);
        request.responseType = 'arraybuffer';
        request.onload = () => {
            if(audioPlayerContext) {
                audioPlayerContext.decodeAudioData(request.response, 
                    (buffer) => {
                        audioBuffers[key] = buffer;
                        if(playOnLoad == true) {
                            play(key);
                        }
                    }, 
                    (error) => {
    
                    });
            }
            else {
                const blob = new Blob([request.response], { type: "audio/mp3" });
                const audioUrl = window.URL.createObjectURL(blob);
                audioBuffers[key] = audioUrl;
                if(playOnLoad == true) {
                    play(key);
                }
            }

        }
        request.send();
    }     

}

const play = (key) => {
    if(audioBuffers[key]) {
        if(audioPlayerContext) {
            var source = audioPlayerContext.createBufferSource(); // creates a sound source
            source.buffer = audioBuffers[key];                    // tell the source which sound to play
            source.connect(audioPlayerContext.destination);       // connect the source to the context's destination (the speakers)
            source.start(0);
        }
        else {
            audioTag.src = audioBuffers[key];
            audioTag.play();
        }
    }
}

export default {init, load, play};