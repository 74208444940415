import { h, Component } from 'preact';

const Sidebar = (props) => {
    return (
        <div className="sidebar">
                <div className="gap-top row align-items-center">
                    <div className="padded"><img src="/assets/img/scott-barron-profile.jpg" style={{width: '100%', maxWidth: 50, borderRadius: '50%'}} /></div>
                    <div className="padded text-white col-hide col-xl-show"><strong>Scott Barron</strong></div>
                </div>
                <div className="gap-top row align-items-center">
                    <div className="padded text-align-center"><a href="#about"><img src="/assets/img/about.svg" style={{width: '75%', maxWidth: 50}} /></a></div>
                    <div className="padded text-white col-hide col-xl-show"><a className="text-white" href="#about">About</a></div>
                </div>
                <div className="row align-items-center">
                    <div className="padded text-align-center"><a href="#activity"><img src="/assets/img/activity.svg" style={{width: '75%', maxWidth: 50}} /></a></div>
                    <div className="padded text-white col-hide col-xl-show"><a className="text-white" href="#activity">Current Activity</a></div>
                </div>
                <div className="row align-items-center">
                    <div className="padded text-align-center"><a href="#availability"><img src="/assets/img/availability.svg" style={{width: '75%', maxWidth: 50}} /></a></div>
                    <div className="padded text-white col-hide col-xl-show"><a className="text-white" href="#availability">Availability</a></div>
                </div>
                <div className="row align-items-center">
                    <div className="padded text-align-center"><a href="#location"><img src="/assets/img/location.svg" style={{width: '75%', maxWidth: 50}} /></a></div>
                    <div className="padded text-white col-hide col-xl-show"><a className="text-white" href="#location">Location</a></div>
                </div>

                <div className="row align-items-center">
                    <div className="padded text-align-center"><a href="#skills"><img src="/assets/img/skills.svg" style={{width: '75%', maxWidth: 50}} /></a></div>
                    <div className="padded text-white col-hide col-xl-show"><a className="text-white" href="#skills">Skills</a></div>
                </div>
                <div className="row align-items-center">
                    <div className="padded text-align-center"><a href="#clients"><img src="/assets/img/clients.svg" style={{width: '75%', maxWidth: 50}} /></a></div>
                    <div className="padded text-white col-hide col-xl-show"><a className="text-white" href="#clients">Clients</a></div>
                </div>
                <div className="row align-items-center">
                    <div className="padded text-align-center"><a href="#experience"><img src="/assets/img/experience.svg" style={{width: '75%', maxWidth: 50}} /></a></div>
                    <div className="padded text-white col-hide col-xl-show"><a className="text-white" href="#experience">Experience</a></div>
                </div>
                <div className="row align-items-center">
                    <div className="padded text-align-center"><a href="#contact"><img src="/assets/img/contact.svg" style={{width: '75%', maxWidth: 50}} /></a></div>
                    <div className="padded text-white col-hide col-xl-show"><a className="text-white" href="#contact">Contact</a></div>
                </div>
                <div className="row align-items-center">
                    <div className="padded text-align-center"><a href="#code"><img src="/assets/img/code.svg" style={{width: '75%', maxWidth: 50}} /></a></div>
                    <div className="padded text-white col-hide col-xl-show"><a className="text-white" href="#code">Code</a></div>
                </div>
                {null/*
                <div className="row align-items-center">
                    <div className="padded text-align-center"><a href="#projects"><img src="/assets/img/projects.svg" style={{width: '75%', maxWidth: 50}} /></a></div>
                    <div className="padded text-white col-hide col-xl-show"><a className="text-white" href="#projects">Projects</a></div>
                </div>
                */}
                <div className="row align-items-center">
                    <div className="padded text-align-center"><a href="#tech"><img src="/assets/img/tech.svg" style={{width: '75%', maxWidth: 50}} /></a></div>
                    <div className="padded text-white col-hide col-xl-show"><a className="text-white" href="#tech">Technologies</a></div>
                </div>
                {/*
                <div className="row align-items-center">
                    <div className="padded text-align-center"><a href="#pay"><img src="/assets/img/pay.svg" style={{width: '75%', maxWidth: 50}} /></a></div>
                    <div className="padded text-white col-hide col-xl-show"><a className="text-white" href="#pay">Pay Me</a></div>
                </div>
                */}
                {null/*
                <div className="row align-items-center">
                    <div className="padded text-align-center"><a href="#feed"><img src="/assets/img/feed.svg" style={{width: '75%', maxWidth: 50}} /></a></div>
                    <div className="padded text-white col-hide col-xl-show"><a className="text-white" href="#feed">Feed</a></div>
                </div>
                */}


        </div>
    );
}

export default Sidebar;