import { h, Component } from 'preact';


class DataStore {
    constructor(data) {
        this.data = data || {};
        this.subscribers = [];
    }

    clear = () => {
        this.data = {};
        this.broadcast();
    }

    setInitial = (data) => {
        this.data = data;
    }

    set = (data) => {
        this.data = data;
        this.broadcast();
    }

    update = (data) => {
        Object.keys(data).forEach((key) => {
            this.data[key] = data[key];
        });
        this.broadcast();
    }

    subscribe = (func) => {
        this.subscribers.push(func);
    }

    unsubscribe = (func) => {

        var newSubscribers = [];

        this.subscribers.forEach((subscriberFunc) => {
            if(subscriberFunc != func) {
                newSubscribers.push(subscriberFunc);
            }
        });
        this.subscribers = newSubscribers;
    }

    broadcast = () => {
        this.subscribers.forEach((subscriberFunc) => {
            subscriberFunc(this.data);
        });
    }
}


var dataStore = new DataStore();



export const withData = (WrappedComponent, updateOnBroadcast = true) => {
    // ...and returns another component...
    return class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                data: {
                    this: "that"
                }
            };
        }

        componentDidMount() {
            if(updateOnBroadcast) {
                dataStore.subscribe(() => {this.forceUpdate();});
            }
        }

        render() {
            // ... and renders the wrapped component with the fresh data!
            // Notice that we pass through any additional props
            return <WrappedComponent data={dataStore.data} {...this.props} />;
        }
    };
}

export const setInitialData = dataStore.setInitial;
export const setData = dataStore.set;
export const clearData = dataStore.clear;
export const updateData = dataStore.update;
