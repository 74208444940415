import { h, Component } from 'preact';


class Popup extends Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <div>
                <div style={{position: "fixed", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "#fff", opacity: 0.7}}></div>
                <div style={{position: "fixed", top: 0, left: 0, right: 0, bottom: 0}} className="flex align-items-center justify-content-center">
                    <div style={{width: 300, backgroundColor: "#fff"}} className="text-align-center">
                        <h3 className="text-align-center">Message</h3>
                        <p className="text-align-center">
                            {this.props.message}
                        </p>
                        <button className="btn btn-primary" onClick={this.props.onClick}>Ok</button>
                    </div>
                </div>
            </div>
            

        )
    }
}

export default Popup;