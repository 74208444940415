


const send = (url, method, data, callbackSuccess, callbackFailure) => {

        try {
            var json = data !== undefined && data !== null ? JSON.stringify(data) : undefined;
            var xhr = new XMLHttpRequest();
            
            xhr.open(method, url, true);
            //xhr.withCredentials = true;
            if(method == "POST" || method == "PUT") {
                xhr.setRequestHeader('Content-type','application/json; charset=utf-8');
            }
            xhr.onload = () => {
                var statusCode = 400;
                    if(xhr.readyState == 4) {
                        statusCode = xhr.status;
                        var responseJSON = xhr.responseText !== undefined && xhr.responseText !== null && xhr.responseText != '' ? JSON.parse(xhr.responseText) : null;
                        if (statusCode >= 200 && statusCode < 300) {
                            callbackSuccess(responseJSON);
                        } else {
                            if(responseJSON !== null) {
                                callbackFailure({statusCode, errorObject: responseJSON});
                            }
                            else {
                                var errorObject = {"":[{message:"Unknown Error"}]};
                                if(statusCode == 400) {
                                    errorObject = {"":[{message:"Bad request / Communications error"}]};
                                }
                                if(statusCode == 401) {
                                    errorObject = {"":[{message:"Unauthenticated / restricted content"}]};
                                }
                                if(statusCode == 403) {
                                    errorObject = {"":[{message:"Unauthorized / forbidden"}]};
                                }
                                if(statusCode == 404) {
                                    errorObject = {"":[{message:"Resource not found"}]};
                                }
                                if(statusCode == 500) {
                                    errorObject = {"":[{message:"Server error"}]};
                                }
                                callbackFailure({statusCode, errorObject});
                            }
                        }
                    }

            }
            xhr.onerror = () => {
                callbackFailure({statusCode: 400, errorObject: {"":[{message:"Communications error"}]}});
            }
            xhr.send(json);
        }
        catch(e) {
            callbackFailure({statusCode: 400, errorObject: {"":[{message:"Communications error"}]}});
        }

}


module.exports.send = send;